@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@700&display=swap');

/* For white -> cblue text Define the gradient-text class with the gradient background */
.gradient-text {
  font-family: 'Roboto Slab', serif;
  background-image: linear-gradient(to right, #ffffff, #5ba7c7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
}

/* Fallback for browsers that don't support -webkit-background-clip */
@supports not (-webkit-background-clip: text) {
  .gradient-text {
    font-family: 'Roboto Slab', serif;
    color: #5ba7c7;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
  }
}

.grid-container {
  display: grid;
  grid-template-rows: auto 1fr auto; /* Three rows with the middle row taking up remaining space */
  grid-template-columns: 1fr; /* All rows have one column */
  gap: 10px; /* Adjust the gap between grid items as needed */
}

/* Define styles for grid items within the grid container */
.grid-item {
  background: red;
}

.header-width-full {
  width: calc(100% - 256px);
}
.header-width-collapsed {
  width: calc(100% - 64px);
}
.customTextWhite * {
  color: #ffffff !important;
}

.custom-underline a {
  text-decoration: underline;
}

/* this class is being used for custom height to show footer without scroll */
.custom-height {
  min-height: calc(100vh - 85px);
}

.customOverFlowClass {
  display: flex;
  overflow-x: auto;
  /* width: calc(100vw - 305px); */
  width: 100%;
  column-gap: 10px;
}

/* This hides the scrollbar for Chrome, Safari and Opera */
.customOverFlowClass::-webkit-scrollbar {
  display: none;
}

.customOverFlowClass {
  -ms-overflow-style: none;
}

.customOverFlowClass {
  scrollbar-width: none;
}

.custom-white {
  filter: brightness(0) saturate(100%) invert(100%) sepia(4%) saturate(2%) hue-rotate(306deg) brightness(118%) contrast(100%);
}
.apexcharts-toolbar {
  z-index: initial !important;
}